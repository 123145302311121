import { BehaviorSubject, Observable } from '@mbrtargeting/metatag-utils';
import { Config } from '../../interfaces/interfaces.js';
import { simpleStorageSwitch, simpleUrlSwitch } from '../../utils/devops-options-helper.js';
import { injectionTarget } from '../decorators/inject.js';
import { selectAdserverGamSettings, selectAdserverRingierSettings, selectCommonSettings, selectLayoutSettings, selectModuleSettings } from './config-selector.js';

export type ConfigSelector<T> = (config: Config) => T;

@injectionTarget()
export class ConfigResolver {

    #config$: BehaviorSubject<Config>;

    constructor(
        private config: Config,
    ) {
        this.#config$ = new BehaviorSubject(config);
        this.evaluateSettingSwitches();
    }

    /**
     * searches for markers in cookies/localStorage/sessionStorage to determine if we need to change any config settings on the fly
     */
    private evaluateSettingSwitches() {
        // sdgCmp=tcf2 or sdgCmp=exttcf2 or sdgCmp=off in url or storage
        const localStorageCmpParam = simpleStorageSwitch('sdgCmp', /^(tcf2|exttcf2|off)$/);
        const urlCmpParam = simpleUrlSwitch(/sdgCmp=(tcf2|exttcf2|off)/i);
        if (urlCmpParam === 'tcf2' || localStorageCmpParam === 'tcf2') {
            this.update(selectModuleSettings('CONSENTMANAGER'), () => ({
                active: true,
                externalCMP: false,
                nonIABCMP: false,
            }));
        }
        if (urlCmpParam === 'exttcf2' || localStorageCmpParam === 'exttcf2') {
            this.update(selectModuleSettings('CONSENTMANAGER'), () => ({
                active: true,
                externalCMP: true,
                nonIABCMP: false,
            }));
        }
        if (urlCmpParam === 'off' || localStorageCmpParam === 'off') {
            this.update(selectModuleSettings('CONSENTMANAGER'), () => ({
                active: false,
            }));
        }

        // sdgClsDemo=1 in url or storage
        if (simpleUrlSwitch(/sdgClsDemo=(1|true)/i) || simpleStorageSwitch('sdgClsDemo', /^(1)$/)) {
            this.update(selectLayoutSettings(), () => ({
                reduceLayoutShift: true,
                centerAds: true,
            }));
        }

        // google - disable mcm for not-whitelistet test systems
        const sdgMcmIdParam = simpleUrlSwitch(/sdgMcmId=([0-9]+)/i) || simpleStorageSwitch('sdgMcmId', /^([0-9]+)$/);
        if (sdgMcmIdParam) {
            const mcmId = /^0+$/.test(sdgMcmIdParam) ? '' : sdgMcmIdParam;
            this.update(selectCommonSettings(), () => ({ dfpChildID: mcmId }));
            this.update(selectAdserverGamSettings(), ({ config }) => ({ config: { ...config, mcmId } }));
            this.update(selectAdserverRingierSettings(), ({ config }) => ({ config: { ...config, mcmId } }));
        }

        // yieldlove
        const ylActiveParam = simpleUrlSwitch(/ylactive=(0|1|true|false)/i)?.toLowerCase();
        const ylDomainParam = simpleUrlSwitch(/yldomain=([a-z0-9_.\-]+)/i);
        const ylVersionParam = simpleUrlSwitch(/ylversion=([0-9a-z_\-.]+)/i);
        const ylLayoutParam = simpleUrlSwitch(/yllayout=([a-z0-9_.\-]+)/i);
        if (ylActiveParam || ylDomainParam || ylVersionParam || ylLayoutParam) {
            this.update(selectModuleSettings('PREBID'), ylSettings => ({
                active: ylActiveParam ? ['1', 'true'].includes(ylActiveParam) : ylSettings?.active,
                yieldloveDomainName: ylDomainParam ?? ylSettings?.yieldloveDomainName,
                yieldloveVersion: ylVersionParam ?? ylSettings?.yieldloveVersion,
                staticAdLayout: ylLayoutParam ?? ylSettings?.staticAdLayout,
            }));
        }

        // netid
        const netidActiveParam = simpleUrlSwitch(/sdgNetId=(0|1|true|false)/) || simpleStorageSwitch('sdgNetId', /^(0|1|true|false)$/);
        const netidDelayLayerTimeParam = simpleUrlSwitch(/sdgNetIdDelay=([0-9]+)/i) || simpleStorageSwitch('sdgNetIdDelay', /^([0-9]+)$/);
        if (netidActiveParam || netidDelayLayerTimeParam) {
            this.update(selectModuleSettings('NETID'), netIdSettings => ({
                active: netidActiveParam ? ['1', 'true'].includes(netidActiveParam) : netIdSettings?.active,
                delayLayerTime: netidDelayLayerTimeParam ? +netidDelayLayerTimeParam : netIdSettings?.delayLayerTime,
            }));
        }

        // utiq
        const utiqActiveParam = simpleUrlSwitch(/sdgUtiq=(0|1|true|false)/) || simpleStorageSwitch('sdgUtiq', /^(0|1|true|false)$/);
        const utiqDelayLayerTimeParam = simpleUrlSwitch(/sdgUtiqDelay=([0-9]+)/) || simpleStorageSwitch('sdgUtiqDelay', /^([0-9]+)$/);
        if (utiqActiveParam || utiqDelayLayerTimeParam) {
            this.update(selectModuleSettings('UTIQ'), utiqSettings => ({
                active: utiqActiveParam ? ['1', 'true'].includes(utiqActiveParam) : utiqSettings?.active,
                delayLayerTime: utiqDelayLayerTimeParam ? +utiqDelayLayerTimeParam : utiqSettings?.delayLayerTime,
            }));
        }
    }

    /**
     * Reading a current configuration object or value with the help of a selector function.
     *
     * The get method provides a flexible way to access specific parts of a configuration object.
     * By passing different selector functions, you can retrieve various pieces of configuration data
     * without needing to know the internal structure of the configuration object.
     *
     * @param selector a config selector function that returns a specific part of the configuration object
     * @returns the selected part of the configuration object
     */
    public get<T>(selector: ConfigSelector<T>): T {
        // TODO: there are some local.js files which modify the config object directly, should we prevent this?
        return selector(this.config);
    }

    /**
     * Returns an observable that emits the selected part of the configuration object whenever it changes.
     *
     * @param selector a config selector function that returns a specific part of the configuration object
     * @returns an observable emitting the selected part of the configuration object
     */
    public get$<T>(selector: ConfigSelector<T>): Observable<T> {
        // TODO: should we add a distinctUntilChanged operator here?
        return this.#config$.map(selector);
    }

    /**
     * Updates the configuration object with the provided partial object.
     *
     * @param selector a config selector function that returns a specific part of the configuration object
     * @param updater a function that returns a partial object to update the selected part of the configuration object
     */
    public update<T>(selector: ConfigSelector<T>, updater: (value: T) => Partial<T>): void {
        // TODO: should we add a deep clone here to prevent mutation of the original object?
        const value = this.get(selector);
        Object.assign(value ?? {}, updater(value));
        this.#config$.next(this.config);
    }
}
